.coldstore-product-content {
    margin: 10vh 0 9vh 0;
    height: 100%;
}

.coldstore-card {
    width: 700px !important;
    max-width: 700px !important;
    padding: 30px 20px !important;
    background-color: #161a1d;
    color: white;
    border-radius: 20px;
    transition: box-shadow 0.3s ease;
    position: relative;
    height: 100%;
}

.coldstore-card:hover {
    box-shadow: rgba(0, 0, 0, 0.7) 0px 10px 60px;
}

.coldstore-cards-container {
    gap: 50px;
}

.coldstore-card-image {
    width: 80%;
    border-radius: 20px;
    display: block;
    margin: 0 auto;
}

.coldstore-card-location {
    font-size: 25px !important;
    text-align: center;
    margin: 10px 0 !important;
    font-weight: 500 !important;
    color: lightgray;
}

.coldstore-card-points {
    /* border: 1px solid red; */
    margin: 0 30px 50px 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.coldstore-card-point {
    font-size: 20px !important;
}

.coldstore-card-project {
    font-size: 20px !important;
    font-weight: 500 !important;
}

.coldstore-card-projects {
    display: flex;
    flex-direction: column;
}

.coldstore-card-subtitle {
    font-size: 20px !important;
    font-weight: 500 !important;
}

.coldstore-card-button {
    background-color: white !important;
    color: black !important;
    font-size: 18px !important;
    width: 100% !important;
}

.coldstore-card-button:hover {
    background-color: lightgrey;
}

.coldstore-card-separate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .coldstore-product-title {
        font-size: 40px !important;
    }

    .coldstore-card-title {
        font-size: 30px !important;
    }

    .coldstore-card {
        width: 90% !important; 
    }

    .coldstore-card:hover {
        box-shadow: none;
    }

    .coldstore-card-points {
        margin: 0 2px 30px 2px;
    }

    .coldstore-card-point {
        font-size: 18px !important;
    }

    
}