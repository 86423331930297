.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 7vh;
    background: linear-gradient(135deg, rgba(1, 1, 1, 0.65), rgba(58, 58, 58, 0.65));
    backdrop-filter: blur(10px);
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
}

a {
    text-decoration: none;
    color: white;
}

.footer-copyright {
    margin-left: 5%;
}

.footer-mail {
    display: flex;
    align-items: center;
    gap: 5%;
    margin-right: 5%;
}

.footer-text {
    color: white;
}

@media screen and (max-width: 1000px) {
    .footer {
        height: 5vh;
    }
}