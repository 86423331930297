.product-content {
    margin: 10vh 0 9vh 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.product-title {
    margin-top: 10px;
    margin-bottom: 30px !important;
    font-weight: 400 !important;
}

.product-title-and-description {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.icon-and-text {
    display: flex;
    gap: 20px;
    /* border: 2px solid red; */
    width: 100%;
    margin-bottom: 20px;
}

.co2gen-wrapper {
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    border-radius: 20px;
    background-color: #f5f5f7;
    width: 80%;
    gap: 20px;
}

.co2gen-container {
    display: flex;
    gap: 50px;
}

.co2gen-title {
    margin: 0 auto !important;
}

.co2gen-image {
    border-radius: 20px;
    width: 100%;
    max-width: 600px;
    max-height: 500px;
    margin: 0 auto;
}

.co2gen-content {
    width: 90%;
    /* border: 2px solid blue; */
    margin: auto;
    margin-right: 40px;
}

.bold-400 {
    font-weight: 500 !important;
}

.co2gen-specifications {
    display: flex;
    gap: 10px;
    /* border: 2px solid red; */
    /* justify-content: center; */
}

@media screen and (max-width: 1000px) {
    .co2gen-content {
        width: 100%;
        margin: 0;
    }

    .co2gen-container {
        display: flex;
        flex-direction: column;
    }

    .co2gen-title {
        font-size: 25px !important;
        text-align: center;
    }

    .co2gen-specifications {
        margin-left: 30px;
    }
}

@media screen and (max-width: 600px) {
    .co2-product-title {
        font-size: 40px !important;
    }

    .co2gen-image {
        width: 100% !important;
    }

    .machine-text {
        font-size: 20px !important;
    }

    .co2gen-specifications {
        margin-left: 0px;
    }
}