.mushroom-product-content {
    margin: 10vh 0 9vh 0;
    height: 100%;
}

.mushroom-card {
    width: 700px !important;
    max-width: 700px !important;
    padding: 30px 20px !important;
    background-color: #181818;
    color: white;
    border-radius: 20px;
    transition: box-shadow 0.3s ease;
    position: relative;
    height: 100%;
}

.mushroom-card:hover {
    box-shadow: rgba(0, 0, 0, 0.7) 0px 10px 60px;
}

.mushroom-cards-container {
    gap: 50px;
}

.mushroom-card-image {
    width: 80%;
    border-radius: 20px;
    display: block;
    margin: 20px auto;
}

.mushroom-card-button {
    background-color: white !important;
    color: black !important;
    font-size: 18px !important;
    width: 100% !important;
}

.mushroom-card-button:hover {
    background-color: lightgrey;
}

@media screen and (max-width: 1000px) {
    .mushroom-product-title {
        font-size: 40px !important;
    }

    .mushroom-card-title {
        font-size: 30px !important;
    }

    .mushroom-card {
        width: 90% !important; 
    }

    .mushroom-card:hover {
        box-shadow: none;
    }    
}