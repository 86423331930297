.product-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.slideImg {
    width: 50%;
}

.slideImg2 {
    width: 60%;
}

@media screen and (max-width: 1000px) {
    .slideImg {
        width: 90%;
    }

    .slideImg2 {
        width: 90%;
    }
}