.product-content {
    margin: 10vh 0 9vh 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.product-title {
    margin-top: 10px;
    margin-bottom: 30px !important;
    font-weight: 400 !important;
}

.product-title-and-description {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.icon-and-text {
    display: flex;
    gap: 20px;
    /* border: 2px solid red; */
    width: 100%;
    margin-bottom: 20px;
}

.ice-machine-wrapper {
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    border-radius: 20px;
    background-color: #f5f5f7;
    width: 80%;
    gap: 20px;
}

.ice-machine-container {
    display: flex;
    gap: 20px;
}

.ice-machine-title {
    margin: 0 auto !important;
}

.two-image-container {
    display: flex;
    /* border: 4px solid cyan; */
    gap: 10px;
    justify-content: center;
}

.ice-machine-image {
    border-radius: 20px;
    width: 40% !important;
}

.ice-machine-content {
    width: 55%;
    /* border: 2px solid blue; */
    margin: auto;
    margin-right: 50px;
}

.bold-400 {
    font-weight: 500 !important;
}

.ice-machine-specifications {
    display: flex;
    gap: 10px;
    /* border: 2px solid red; */
    /* justify-content: center; */
}

@media screen and (max-width: 1000px) {
    .icon-and-text {
        width: 90%;
        display: flex;
        /* justify-content: space-between; */
    }

    .ice-machine-image {
        width: 50% !important;
        margin: 0 auto;
    }

    .description-text {
        font-size: 18px !important;
        width: 100%;
        text-align: left !important;
    }

    .ice-machine-content {
        width: 100%;
        margin: 0;
    }

    .ice-machine-wrapper {
        width: 80%;
        padding: 10px 15px;
    }

    .ice-machine-container {
        display: flex;
        flex-direction: column;
    }

    .ice-machine-title {
        font-size: 30px !important;
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .product-title {
        font-size: 40px !important;
    }

    .machine-text {
        font-size: 20px !important;
    }
}