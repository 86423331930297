.product-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 1000px) {
    .mushroom-slides {
        width: 100%;
    }
}