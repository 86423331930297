.product-content {
    margin: 10vh 0 9vh 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.product-grid {
    gap: 40px;
}

.product-title {
    margin: 10px 0px !important;
    font-weight: 400 !important;
}

.product-title-and-description {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.product-card {
    display: flex;
    margin: 0 auto;
    flex-direction: column !important;
    align-items: center;
    gap: 15px;
    max-width: 40% !important;
    /* border: 2px solid blue; */
    background-color: #f5f5f7;
    border-radius: 40px;
    padding: 30px 20px;
}

.processChiller-image {
    width: 80% !important;
    border-radius: 15px;
    /* border: 10px solid red; */
}

.card-header {
    text-align: center;
}

.card-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 90%;
    /* height: 100%; */
    justify-content: center;
    /* border: 2px solid green; */
}

.card-specifications {
    display: flex;
    width: 100% !important;
    gap: 10px !important;
    justify-content: left;
    margin-left: 30px;
    /* border: 2px solid blue; */
}

@media screen and (max-width: 1000px) {
    .product-card {
        max-width: 100% !important;
        padding: 20px 10px;
        width: 90%;
    }

    .product-content {
        margin: 10vh 0 8vh 0;
    }

    .product-title {
        font-size: 45px !important;
        font-weight: 900 !important;
    }

    .card-header-text {
        font-size: 30px !important;
        font-weight: 500 !important;
    }

    .card-specifications {
        width: 70%;
    }
}

@media screen and (max-width: 600px) {
    .card-specifications {
        width: 90%;
        margin: 0;
    }
}