.product-content {
    margin: 10vh 0 9vh 0 !important;
    height: 100%;
}

.modelling-3d {
    width: 98%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    /* border: 1px solid black; */
}

.potato-grid1-image {
    /* border: 1px solid blue; */
    width: 300px;
}

.potato-grid1-item {
    display: flex;
    align-items: center;
    flex-direction: column !important;
    gap: 5px;
}

.potato-grid1-image-description {
    font-size: 20px !important;
    text-align: center;
}

.potato-grid2 {
    margin-top: 40px;
    /* width: 90% !important; */
}

.potato-grid2-item {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    gap: 10px;
}

.potato-grid2-image {
    width: 80%;
    border-radius: 20px;
}

.potato-grid2-card {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 20px;
    background-color: #f5f5f7;
    border-radius: 20px;
}

.potato-grid2-image-description {
    font-size: 20px !important;
    text-align: center;
    font-weight: 500 !important;
    color: grey;
}

@media screen and (max-width: 1000px) {
    .product-content {
        margin: 10vh 0 8vh 0 !important;
    }

    .potato-title {
        font-size: 38px !important;
    }

    .potato-subtitle {
        font-size: 28px !important;
    }

    .potato-grid1-image {
        width: 70%;
    }

    .potato-grid1-item {
        display: flex;
        flex-direction: column;
    }

    .potato-grid1-image-description {
        font-size: 18px !important;
    }

    .potato-grid2-card {
        width: 80%;
    }

    .potato-grid2-image {
        width: 100%;
    }
}