.contactus-content {
    margin: 18vh 15% 7vh 15%;
}

#contactus-contact_us {
    width: 40%;
    float: right;
    /* border: 2px solid red; */
    margin: 90px 0;
}

.contactus-main {
    display: flex;
    flex-direction: column;
    /* border: 1px solid rebeccapurple; */
    margin: 60px 30px;
    gap: 40px;
}

.contactus-call {
    display: flex;
    gap: 30px;
    align-items: center;
}

.contactus-numbers {
    display: flex;
    gap: 20px;
}

.contactus-names {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.contactus-name-number {
    display: flex;
    flex-direction: column;
}

.contactus-mail, .contactus-address {
    display: flex;
    gap: 30px;
    align-items: center;
    max-width: 75%;
    /* border: 1px solid red; */
}

.blue {
    color: blue;
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

@media screen and (max-width: 1000px) {
    #contactus-contact_us {
        margin: 30px auto;
        width: 50%;
    }

    .contactus-content {
        display: flex;
        flex-direction: column;
        margin: 12vh 8%;
    }

    .contactus-main {
        margin: 0 0px;
        gap: 60px;
        font-size: 16px;
    }

    #contactus-title {
        font-size: 40px;
    }

    .contactus-mail, .address {
        max-width: 100%;
    }

    .contactus-text {
        font-size: 14px !important;
    }
}