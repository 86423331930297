.navbar-container {
    border-bottom: 1px solid grey;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 998;
    height: 10vh;
    transition: height 0.3s ease;
    background-color: rgba(45, 45, 45, 0.6);
    backdrop-filter: saturate(180%) blur(15px);
    color: white;
}

.title {
    font-weight: 900 !important;
    cursor: pointer;
    user-select: none;
}

.navbar {
    display: flex;
    height: 60%;
    margin: 2vh 2vh 0 2vh;
    justify-content: space-between;
    align-items: top;
}

.navbar-list {
    align-items: top;
}

.product-list {
    display: none;
}

.product-list.hovered {
    display: grid;
    text-align: center;
    height: 100%;
    grid-template-columns: 21% 21% 21% 21%;
    grid-template-rows: 20% 20%;;
    color: white;
    row-gap: 20%;
    column-gap: 4%;
}

ul {
    margin-top: 18px;
    display: flex;
    padding: 0;
    gap: 40px;
    list-style: none;
}

.navbar-container.hovered {
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: top;
    gap: 2px;
}

.products {
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
}

.mobile-products {
    display: none;
}

.fa-caret-down.hovered {
    display: none;
}

.fa-caret-up {
    display: none;
}

.fa-caret-up.hovered {
    display: block;
}

li:hover {
    cursor: pointer
}

.mobile {
    display: none;
}

.fas.mobile-list-open {
    display: none;
}

.fa-bars, .fa-times {
    cursor: pointer;
}

.fa-times {
    color: white;
    position: fixed;
    top: 4%;
}

input,
textarea,
button,
select,
i {
    -webkit-tap-highlight-color: transparent;
}

.blur.active {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: 60%;
    transition: 0.6s ease;
}

@media screen and (max-width: 1000px) {
    .navbar-container {
        height: 10%;
    }

    .title {
        margin-top: 0;
    }

    .list {
        background: linear-gradient(75deg, rgb(9, 9, 9), rgb(32, 30, 30));
        backdrop-filter: blur(15px);
        display: flex;
        position: fixed;
        top: 0;
        right: -100%;
        flex-direction: column;
        padding: 17% 6%;
        padding-top: 7em;
        gap: 20px;
        height: 100vh;
        /* background-color: black; */
        color: white;
        list-style: none;
        transition: 0.3s ease;
        margin: 0;
        font-size: 18px;
        font-weight: 500;
    }
    .list.active {
        right: -1%;
    }
    .navbar {
        height: 100%;
        align-items: center;
        /* height: 70%; */
        margin: 0vh 1vh 0 1vh;
    }
    .mobile {
        display: block;
    }
    .products, .fa-caret-down, .fa-caret-up {
        display: none;
    }
    .fa-caret-up.hovered {
        display: none;
    }
    .mobile-products {
        display: block;
    }
}