.wasteHeatRecovery-cover-images {
    width: 600px;
}

.product-content {
    margin: 12vh 0 9vh 0;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.product-images {
    display: flex;
    justify-content: center;
    gap: 5%;
}

.case-study {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    transition: box-shadow 0.3s ease;
    border-radius: 15px;
    padding: 20px 18px;
}

.case-study:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 60px;
}

.whr-btn {
    font-size: 18px !important;
    color: black !important;
    border-color: black !important;
}

.whr-locations {
    font-size: 30px !important;
}

.divider {
    width: 80%;
    border-top: 3px solid #c0c0c0; 
    margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
    .product-content {
        margin: 10vh 20px 8vh 20px;
        display: flex;
    }

    .product-title {
        font-size: 35px !important;
    }

    .product-images {
        flex-direction: column;
        gap: 35px;
    }

    .wasteHeatRecovery-cover-images {
        width: 95%;
    }

    .whr-locations {
        font-size: 22px !important;
    }
}