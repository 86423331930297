.flori-card {
    display: flex;
    justify-content: center;
    gap: 50px;
    width: 80%;
    border-radius: 20px;
    margin: 0 auto 30px auto;
    padding: 30px 40px;
    background-color: #f5f5f7;
}

.flori-image {
    border-radius: 20px;
}

.flori-card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.flori-info {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.flori-location {
    font-size: 25px !important;
}

.flori-subtitle {
    font-size: 20px !important;
    font-weight: 500 !important;
}

.flori-text {
    font-size: 20px !important;
}

@media screen and (max-width: 1000px) {
    .flori-title {
        font-size: 42px !important;
    }
    .flori-card {
        flex-direction: column;
        gap: 20px;
        padding: 20px;
    }

    .flori-image {
        width: 90%;
        display: block;
        margin: auto;
    }

    .flori-card-content {
        gap: 20px;
    }

    .flori-text {
        font-size: 16px !important;
    }

    .flori-info {
        gap: 5px;
    }
}