.product-content {
    margin: 10vh 0 9vh 0 !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.product-grid {
    gap: 40px;
}

.product-title {
    margin: 10px 0px !important;
    font-weight: 400 !important;
}

.product-title-and-description {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.product-card {
    display: flex;
    margin: 0 auto;
    flex-direction: column !important;
    align-items: center;
    gap: 15px;
    max-width: 40% !important;
    /* border: 2px solid blue; */
    background-color: #f5f5f7;
    border-radius: 40px;
    padding: 30px 20px;
}

.heatpump-image {
    position: relative;
    width: 90% !important;
    width: 100%;
    border-radius: 15px;
    /* border: 10px solid red; */
}

.heatpump-image-container {
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
    /* border: 1px solid blue; */
}

.heatpump-btns {
    display: flex;
    gap: 20px;
}

.heatpump-btn {
    padding: 5px 10px !important;
    text-transform: unset !important;
    color: black !important;
    border-color: black !important;
    background-color: white;
    font-size: 20px !important;
}

div {
    -webkit-tap-highlight-color: transparent;
}

.card-header {
    text-align: center;
}

.card-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 90%;
    /* height: 100%; */
    justify-content: center;
    /* border: 2px solid green; */
}

.card-specifications {
    display: flex;
    width: 50%;
    justify-content: space-between;
    /* border: 2px solid blue; */
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: black;
    opacity: 1;
    background-color: white;
    /* background: rgba(0, 0, 0, 0.2); */
  }
  
  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }

.swiper-button-prev::after, .swiper-button-next::after {
    color: white;
    font-weight: 900;
}
  
  

@media screen and (max-width: 1000px) {
    .product-card {
        max-width: 100% !important;
        padding: 20px 10px;
        width: 90%;
    }

    .product-content {
        margin: 10vh 0 8vh 0;
    }

    .product-title {
        font-size: 45px !important;
        font-weight: 900 !important;
    }

    .card-header-text {
        font-size: 30px !important;
        font-weight: 500 !important;
    }

    .card-specifications {
        width: 70%;
    }
}

@media screen and (max-width: 600px) {
    .card-specifications {
        width: 100%;
    }
}