.about-content {
    margin: 11vh 0 7vh 0;
}

.about-part {
    display: flex;
    justify-content: center;
    gap: 50px;
    padding: 0 0 125px 0;
    /* border: 2px solid firebrick; */
}

.about-part-wrapper {
    display: flex;
    flex-direction: column !important;
    position: relative;
    gap: 40px;
}

.about-text-container {
    width: 55%;
    /* border: 2px solid green; */
    display: flex;
    align-items: center;
}

.about-text {
    font-size: 22px !important;
}

.about-image {
    width: 500px;
}

.gray {
    background-color: lightgray;
}

.custom-shape-divider-bottom-1689350389 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1689350389 svg {
    position: relative;
    display: block;
    width: calc(140% + 1.3px);
    height: 124px;
}

.lightgray .shape-fill {
    fill: lightgray;
}

.white .shape-fill {
    fill: white;
}

@media screen and (max-width: 1000px) {
    .about-content {
        margin: 12vh 0 7vh 0 !important;
    }

    .about-part {
        flex-direction: column;
        gap: 20px;
        padding: 0 0 50px 0;
        margin-bottom: 20px;
    }

    .about-title {
        font-size: 35px !important;
    }

    .about-part-wrapper {
        gap: 20px;
    }

    .about-text-container {
        width: 80%;
        margin: 0 auto;
    }

    .about-subtitle {
        font-size: 35px !important;
    }

    .about-image {
        width: 80%;
        display: block;
        margin: auto;
    }

    .about-text {
        font-size: 18px !important;
        /* text-align: center; */
    }
}