* {
  margin: 0;
}

.app-content {
  margin: 10vh 0 7vh 0;
  height: 100%;
}

.app-title-and-caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  /* border: 1px solid green; */
}

.app-title-and-caption-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-black, .app-white {
  padding: 5% 6%;
}

.app-machine-info {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  gap: 90px;
}

.app-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-black {
  background-color: black;
  color: white;
}

.app-white {
  background-color: white;
  color: black;
}

.app-title {
  font-size: 60px !important;;
}

.app-caption {
  font-size: 30px !important;
}

#app-machine-pic {
  width: 80%;
}

#app-machine-pic-mobile {
  width: 65%;
}

.app-machine-info-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 35px 0;
}

.app-title-mobile {
  font-weight: 500 !important;
}

@media screen and (max-width: 1000px) {
  .app-content {
    margin: 9vh 0 5vh 0;
  }

  .app-black, .app-white {
    flex-direction: column;
  }

  .app-caption {
    color: blue !important;
  }

}