@media screen and (max-width: 1000px) {
    .coming-soon-title {
        margin-top: 140px !important;
    }
}

@media screen and (max-width: 600px) {
    .coming-soon-title {
        margin-top: 80px !important;
        font-size: 50px !important;
    }

    .coming-soon-image {
        width: 300px;
    }
}